@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    @apply bg-slate-100
}
ul > li {
    @apply mb-4 bg-white p-6 rounded-2xl;
}
ul > li > ul > li {
    @apply mb-0 px-0 py-3 bg-transparent border-b rounded-none;
}
ul>li>ul>li:last-child {
    @apply border-0;
}
